import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../../components/header/header'
import WithHeaderLayour from '../../layouts/with-header'
import Footer from '../../components/footer/footer'
import Hero from '../../components/hero/hero'
import PageHeader from '../../components/page-header/page-header'
import SeloAbracam from '../../components/selo-abracam/selo-abracam'

const IndexPage = ({ data, location }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Header currentPath={location?.pathname} />
      <PageHeader
        headline={`Moedas`}
        description={`A JMS garante economia para quem prefere viajar preparado e não preocupado, seja com a confiabilidade do papel moeda ou a praticidade do cartão pré-pago.`}
      />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>Moedas estrangeiras em espécie com condições que valem a pena</h2>
        <div>
          <ul>
            <li>
              Pague como desejar: transferência bancária, cartões de crédito
              e/ou débito ou dinheiro
            </li>
            <li>
              Disponibilidade em lojas físicas, via WhatsApp, e-mail e telefone
            </li>
            <li>Delivery rápido e prático para sua comodidade</li>
            <li>Equipe experiente e pontual</li>
          </ul>
        </div>
        <h2>Moedas em Espécie Disponíveis</h2>
        <div>
          <ul>
            <li>Dólar americano (USD)</li>
            <li>Euro</li>
            <li>Libra esterlina</li>
            <li>Dólar canadense</li>
            <li>Dólar australiano</li>
            <li>Dólar neozelandês</li>
            <li>Iene</li>
            <li>Peso chileno</li>
            <li>Peso mexicano</li>
            <li>Franco suíço</li>
            <li>Entre muitas outras (consulte nossa equipe para saber mais)</li>
          </ul>
        </div>
      </div>
      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MoedasQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
